const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
  brand: "HealthLytix",
  year,
  productLink: "https://www.healthlytix.ai",
  AutoCloseMobileNav: true,                         // true, false. Automatically close sidenav on route change (Mobile only)
  color: {
    primary: "#2878B7",
    success: "#8BC34A",
    info: "#66BB6A",
    infoAlt: "#7E57C2",
    warning: "#FFCA28",
    danger: "#F44336",
    text: "#3D4051",
    gray: "#EDF0F1"
  },
  settings: {
    layoutBoxed: false,                             // true, false
    navCollapsed: true,                            // true, false
    navBehind: false,                               // true, false
    fixedHeader: true,                              // true, false
    sidebarWidth: "middle",                         // small, middle, large
    colorOption: "11",                              // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: "light",                                 // light, gray, dark
  },
  cognito: {
    USER_POOL_ID: "us-east-1_XyrNqnScR",
    APP_CLIENT_ID: "3nfrj0s2a1va90s7ssi8ke0vo2",
    REGION: "us-east-1",
    IDENTITY_POOL_ID: "us-east-1:b8ca6bd1-bd0c-45d2-9efc-89c2e6e49288"
  },
  apiGateway: {
    URL: "https://api.dev.healthlytix.io",
    REGION: "us-east-1"
  },
  s3: {
    DATALAKE_BUCKET: "healthlytix-dev-datalake"
  },
  sessions: {
    inactivityThreshold: 1000 * 60 * 30 // 30 minutes
  }
};

module.exports = APPCONFIG;
